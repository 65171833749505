//import Libraries
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

//import Components

//import Styles
import './ContentBox.css';
let addClass = "";

class ContentBox extends Component{
    
    render(){
        const more = this.props.more ? (
            <div className="More"><Link to={this.props.more}>more ⇀</Link></div>
        ) : (
            null
        )
        if(this.props.className) addClass = " "+this.props.className
        else addClass = "";
        return(
            <div className={"ContentBox"}>
                {more}
                <h1 className="Title">{this.props.title ? this.props.title : "Content Title"}</h1>
                {this.props.children ? <div className={"Content"+addClass}>{this.props.children}</div> : (
                <div className="Content" style={{width:"100%", minHeight:"250px", backgroundColor:"white", opacity: 0.2}}></div>)}
            </div>
        )
    }
}


export default ContentBox;