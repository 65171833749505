//import Libraries
import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import InstagramEmbed from 'react-instagram-embed';

//import Components
import Layout from '../../hoc/Layout/Layout';
import ContentBox from '../../components/ContentBox/ContentBox';
import Newscard from '../../components/Newscard/Newscard';

import data from '../../data/data.json';

//import Style classes
import './News.css'

class News extends Component {
    state = {
        moreCount: 1
    }

    moreClickHandler = ()=>{
        this.setState((prevState) => ({
            moreCount: prevState.moreCount + 1
          }));
    }

    componentDidMount() {
        // document.title = "Latest News - Wayway Studio";
    }

    render(){
        const news = [...data.news]
        const newsCards = news.reverse().slice(0,6*this.state.moreCount).map((obj, idx)=>{
            return <Newscard data={obj} key={idx} style={idx%3 === 2 ? {marginRight: "0px"} : null}/>
        })
        return (
            <Layout>
                <Helmet>
                    <title>{"News & Social Media - Wayway Studio"}</title>
                    <meta name="description" content="Browser all News and Social Activities Wayway Studio, a game development team based in South Korea who released Rowrow, RGBit and more well designed indie games" />
                </Helmet>
                <ContentBox title="News">
                    {newsCards}
                </ContentBox>
                {news.length > this.state.moreCount*6 ? (
                    <div className="MoreButton" onClick={this.moreClickHandler}>See more</div>
                ):null}
                <ContentBox title="Social Media" className="SocialMedia">
                    <TwitterTimelineEmbed 
                        className="TwitterWidget"
                        sourceType="widget"
                        screenName="waywaystudio"
                        theme= "dark"
                        linkColor="#cfac39"
                        noFooter={true}
                        noHeader={true}
                        noScrollbar={true}
                        transparent={true}
                        options={{height: 2000, width: "100%"}}/>
                    <div className="Dummy" style={{width: 50}}></div>
                    <div className="InstagramPostWrapper">
                        {data.instagram.map((obj, idx)=>{
                            return <InstagramEmbed
                                    url={'https://www.instagram.com/p/'+obj}
                                    style={{
                                        
                                    }}
                                    className="InstagramPost"
                                    hideCaption={false}
                                    containerTagName='div'
                                    protocol=''
                                    injectScript/>
                        })}
                    </div>
                </ContentBox>
            </Layout>
        )
    }
}

export default News;
