//import Libraries
import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

//import Components
import Layout from '../../hoc/Layout/Layout';
import ColorExperiment from '../../components/ColorExperiment/ColorExperiment';


//import Style classes


class RgbitColorGen extends Component {
    componentDidMount() {

    }

    render(){
        return (
            <Layout>
                <Helmet>
                    <title>{"Random Color Generator for RGBit Study- Wayway Studio"}</title>
                    <meta name="description" content="Random Color Generator for a study to make RGBit, a color mixing game. This study was used to experiment an area of colors that generate a consistant amount of pleasure to an eye" />
                </Helmet>
                <ColorExperiment />
            </Layout>
        )
    }
}

export default RgbitColorGen;
