//import Libraries
import React, {Component} from 'react';

//import Components

//import Style classes
import "./Factsheet.css";

class factsheet extends Component{

    state = {
        open: false
    }
    
    toggleOpen = ()=>{
        this.setState((prevState)=>{
            return {
                open: !prevState.open
            }
        })
    }

    // handleBlur = () => {
    //     this.setState((prevState)=>{
    //         return {
    //             open: false
    //         }
    //     })
    // }

    componentDidMount() {
        window.addEventListener('click', e => {
            if(this.state.open &&
                !this.node.contains(e.target)
            ){
                this.setState({open: false})
            }
        })
        window.addEventListener('touchend', e => {
            if(this.state.open &&
                !this.node.contains(e.target)
            ){
                this.setState({open: false})
            }
        })
    }

    render(){

    
    const { status } = this.props.data;
    let date;
    let platforms;
    if(status === "released"){
        date = (
            <p>
                <strong>Release date:</strong><br/>
                {this.props.data.releaseDate}
            </p>
        )
        platforms = (
            <p>
                <strong>Platforms:</strong><br/>
                {Object.keys(this.props.data.released).map((key, idx)=>{
                    if(this.props.data.released[key] !== null){
                        return <a key={idx} target="_blank" rel="noopener noreferrer" href={this.props.data.released[key]}>{key === "Steam" ? "Steam (Win/Mac)" : key}<br/></a>
                    }
                    return null;
                })}
            </p>
        )
    }else if(status === "upcoming"){
        date = (
            <p>
                <strong>Release date:</strong><br/>
                {this.props.data.upcomingDate}
            </p>
        )
        platforms = (
            <p>
                <strong>Platforms:</strong><br/>
                {Object.keys(this.props.data.upcoming).map((key, idx)=>{
                    if(this.props.data.upcoming[key] !== null){
                        return <a key={idx} target="_blank" rel="noopener noreferrer" href={this.props.data.released[key]}>{key === "Steam" ? "Steam (Win/Mac)" : key}<br/></a>
                    }
                    return null;
                })}
            </p>
        )
    }
    
    return (
        <div className={this.state.open ? "FactsheetWrapper open" : "FactsheetWrapper"}
            ref={n => (this.node = n)}>
            <div className={this.state.open ? "ToggleButton open" : "ToggleButton"}
                 onClick={this.toggleOpen}>
                {this.state.open ? "Close" : "Factsheet"}
            </div>
            <div className="Factsheet">
            <h3>Factsheet</h3>
                <hr/>
                <p>
                    <strong>Developer:</strong><br/>
                    <a href="/">Wayway</a><br/>
                    Based in Seoul, South Korea
                </p>
                {date}
                {platforms}
                <p>
                    <strong>Website:</strong><br/>
                    <a href="http://www.waywaystudio.com">http://www.waywaystudio.com</a>
                </p>
                <p>
                    <strong>Price:</strong><br/>
                    {this.props.data.status === "experimental" ? "Not Available" : this.props.data.price}
                </p>
                <p>
                    <strong>Availability:</strong><br/>
                    {this.props.data.status === "experimental" ? "Not Available" : "Digital Download"}
                </p>     
                <p>
                    <strong>Languages:</strong><br/>
                    English
                </p>   
                {this.props.data.status === "experimental" ? null : <p>
                    <strong>ESRB:</strong><br/>
                        ACB G<br/>
                        USK 0<br/>
                        PEGI 3<br/>
                        IARC 3<br/>
                    </p>}
                <p>
                    <strong>Press Contact:</strong><br/>
                    <a href="mailto:waywaygroup@gmail.com">waywaygroup@gmail.com</a>
                </p>
                <p>
                    <strong>Social:</strong><br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/waywaystudio">Twitter</a><br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Wayway-Studio-1005643332955455">Facebook</a><br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/wayway_studio">Instagram</a><br/>
                </p>
                {this.props.children}
            </div>
        </div>
    )
    }
}

export default factsheet;
