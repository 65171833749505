//import Libraries
import React from 'react';

//import Components
import Layout from '../../hoc/Layout/Layout';
import ContentBox from '../../components/ContentBox/ContentBox';
import Namecard from '../../components/Namecard/Namecard';
import ImageCard from '../../components/ImageCard/ImageCard';

import data from '../../data/data.json';

//import Style classes

const about = (props) => {
    const namecards = data.team.map((obj, idx)=>{
        return (
            <Namecard data={obj} key={idx}/>
        )
    })
    return (
        <Layout>
            <ContentBox title="Wayway Studio">
                <ImageCard path="photo/wayway_small.jpg" widthRatio={[3,2]}>
                    <h3>We make Games<br/>and more...</h3>
                    <p>We hope to develop our games in various different perspectives. Wayway means we will not go a sigular path. Our logo explains a lot about us. The five colors in our logo which is RED, BLUE, YELLOW, WHITE, BLACK is a traditional Korean color group called "Oh-bang-saek". It can be translated as 'five directional colors'. We liked this idea that five colors mean a variety of "ways". Which directly reflects our philosophy.<br/><br/>Looking forward to meet you guys with great games!</p>
                </ImageCard>
            </ContentBox>
            <ContentBox title="Our Team" className="NamecardWrapper">
                {namecards}
                <div className="Namecard">
                    <h3>Join us</h3>
                    <p>Based in South Korea, We are always looking for great folks to join us. Contact us via</p>
                    <p>waywaygroup@gmail.com</p>
                </div>
            </ContentBox>
            <ContentBox title="Contact us">
                <ImageCard path="photo/contact-240620.jpg" >
                    <h3>Pangyo,<br/>South Korea</h3>
                    <p>We are a small indiedev based in South Korea. We think games can be more than games.</p>
                    <p>waywaygroup@gmail.com</p>
                    <div className="Links">
                        <a href="https://twitter.com/waywaystudio">twitter</a>
                        <a href="https://www.instagram.com/wayway_studio/">instagram</a>
                        <a href="https://www.youtube.com/channel/UCuVD12lB2EMzZiA6d57ZfWA">youtube</a>
                    </div>
                </ImageCard>
            </ContentBox>
        </Layout>
    )
}

export default about;
