//import Libraries
import React from 'react';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';

//import Components
import Layout from '../../hoc/Layout/Layout';
import ContentBox from '../../components/ContentBox/ContentBox';
import PromoGooglePlay from './Platform/GooglePlay';
import PromoAppStore from './Platform/AppStore';
import PromoSteam from './Platform/Steam';
import WorkCard from '../../components/WorkCard/WorkCard';

import data from '../../data/data.json';
import worksData from '../../data/workdata';

//import Style classes
import './PromotionInstructions.css';

const promotionInstructions = (props) => {
    const titleText = {
        googleplay: "Google Play",
        appstore: "App Store",
        steam: "Steam"
    }
    const platform = props.match.params.platform ? props.match.params.platform : "steam";
    const pageType = 
    platform === "steam" ? <PromoSteam/> : 
    platform === "googleplay" ? <PromoGooglePlay/> : 
    platform === "appstore" ? <PromoAppStore/> : null;

    const platformName = titleText[platform];
    const addClass =
    platform === "steam" ? ["active","",""] : 
    platform === "googleplay" ? ["","active",""] : 
    platform === "appstore" ? ["","","active"] : null;;

    return (
        <Layout>
            <div className="InstructionTitle">
                <h1>{"How to use the Promotion Key\n프로모션 코드 사용하는 방법"}</h1>
                <p>{"Select your Platform\n사용하실 플랫폼을 선택하세요"}</p>
            </div>

            <div className="PlatformLinks">
                <Link className={"PlatformCard "+addClass[0]} to="/promotion-instruction/steam">
                    <img src={require("../../assets/icons/steam.svg")} alt=""/>
                    <p>Steam</p>
                </Link>
                <Link className={"PlatformCard "+addClass[1]} to="/promotion-instruction/googleplay">
                    <img src={require("../../assets/icons/googleplay.svg")} alt=""/>
                    <p>Google Play</p>
                </Link>
                <Link className={"PlatformCard "+addClass[2]} to="/promotion-instruction/appstore">
                    <img src={require("../../assets/icons/appstore.svg")} alt=""/>
                    <p>App Store</p>
                </Link>
            </div>
            {pageType ? 
            <ContentBox title={" "}>
                <Helmet>
                    <title>{"How to Use" + platformName + " Promotion Keys - Wayway Studio"}</title>
                </Helmet>
                {pageType}
            </ContentBox>
            : <ContentBox title="Sorry, can not find the page you are looking for"/>}
            <ContentBox title="Games by Wayway Studio">
                {Object.keys(worksData).map((key, idx)=>{
                    let data = worksData[key]
                    return(
                        <WorkCard fullpage={false} 
                            title={data.title} 
                            link={key}
                            data={data} key={idx}></WorkCard>
                    )
                })}
            </ContentBox>
        </Layout>
    )
}

export default promotionInstructions;
