const d3 = require('d3-color');



//Generates 0~int random ints
const RandomInt = (int)=>{
    return Math.floor(Math.random() * int);
}

const hslToRgb = (h, s, l)=>{
    
}

export { RandomInt , d3};