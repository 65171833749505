//import Libraries
import React from 'react';

//import Components

//import Style classes
import "./VideoContent.css";


const videoContent = (props) => {
    let classes = [
        "WorkContent",
        "VideoContent",
        props.data.size === 3 ? "third" 
        : props.data.size === 2 ? "half" 
        : props.data.size === 1 ? "full" : "",
    ]

    return (
        <div className={classes.join(" ")} >
            <iframe 
                title="video"
                src={props.data.link+"/?autoplay=1&showinfo=0&autohide=1&rel=0&mute=1"}
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen="true">
            </iframe>
        </div>
    )
}

export default videoContent;