//import Libraries
import React from 'react';

//import Components


//import Style classes

const googlePlay = (props) => {

    return (
        <div class="PromotionInstructions">
          <h3>스마트폰에서의 사용</h3>
          <ol>
            <li>
                Google Play 스토어 앱 <img src="//lh3.googleusercontent.com/pA0Oo_R7q7rvIY2q8EHo5WD6X3NG110lgtezklJGplOW8CEa5AYZhZ8ohUIRG21oP-fk=w18-h18" width="18" height="18" alt="Google Play" title="Google Play"/>을 엽니다.
            </li>
            <li>
                메뉴&nbsp;<img src="//storage.googleapis.com/support-kms-prod/CD148BFC3EE3B5328DAFE08E2B6AA95B73B7" width="18" height="18" alt="메뉴" title="메뉴"/><img src="//lh3.googleusercontent.com/--hN1HovdyMvaDlFGhrt33r4Ozz1zCdlpLvLkGQTnA5PTOyzOaZkOW5o3091g9_7L1_u=w13-h18" width="13" height="18" alt="다음" title="다음"/><strong>코드 사용</strong>을 탭합니다.</li>
            <li>코드를 입력합니다<span id="68769S"></span>.<span id="68769E"></span></li>
            <li><strong>사용</strong>을 탭합니다.</li>
          </ol>
          <h3>컴퓨터에서의 사용</h3>
          <ol>
            <li><a href="https://play.google.com/redeem" target="_blank" rel="noopener">play.google.com/redeem</a> 페이지를 방문합니다.</li>
            <li>코드를 입력합니다.</li>
            <li><strong>코드 사용</strong>을 클릭합니다.</li>
          </ol>
          <hr/>
          <h3>on Mobile Devices</h3>
          <ol>
            <li>
                Open the Google Play Store App <img src="//lh3.googleusercontent.com/pA0Oo_R7q7rvIY2q8EHo5WD6X3NG110lgtezklJGplOW8CEa5AYZhZ8ohUIRG21oP-fk=w18-h18" width="18" height="18" alt="Google Play" title="Google Play"/>
            </li>
            <li>
              Go to <strong>Menus </strong>
               and Tap  
                <strong> Redeem</strong></li>
            <li>Type in the Promotion Code<span id="68769S"></span>.<span id="68769E"></span></li>
            <li>Tap the <strong>REDEEM</strong> button</li>
          </ol>
          <h3>on Desktop</h3>
          <ol>
            <li>Follow this link <a href="https://play.google.com/redeem" target="_blank" rel="noopener">play.google.com/redeem</a></li>
            <li>Type in the Promotion Code</li>
            <li>Tap the <strong>REDEEM</strong> button</li>
          </ol>
          <img src={require('../../../assets/special/promotion-instruction/google-play-redeem.png')} alt="App Store Promotion Code Usage"/>
        </div>
    )
}

export default googlePlay;
