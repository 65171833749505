//import Libraries
import React, {Component} from 'react';

//import Components


//import Styles
import './Newscard.css';

let addClass = "";
let newsImage;

class Newscard extends Component{
    state = {

    }
    clickHandler = (e)=>{
        
    }
    render(){
        const linkTags = this.props.data.link.map((obj, idx)=>{
            return (
                <a key={idx} target="_blank" rel="noopener noreferrer" href={obj.link}>{obj.text}</a>
            )
        })


        if(this.props.data.image !== null){
            newsImage = <img src={require("../../assets/news/"+this.props.data.image)} alt=""></img>
        }else{
            newsImage = <img src={require("../../assets/news/baseimage.png")} alt=""></img>
        }
        return(
            <div className={"Newscard"+addClass} onClick={this.clickHandler} style={this.props.style}>
                <div className="NewsImage">
                    {newsImage}
                </div>
                <div className={"NewsText"}>
                    <p className="RightAlign">{this.props.data.date}</p>
                    <h3>{this.props.data.title}</h3>
                    <p>{this.props.data.text}</p>
                    <div className="Links">{linkTags}</div>
                </div>
            </div>
        )
    }
}


export default Newscard;