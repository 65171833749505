//import Libraries
import React from 'react';

//import Components

//import Style classes
import "./TextContent.css";

const textContent = (props) => {

    const style = { };
    if(props.data.height) style.height = props.data.height;
    if(!props.data.text) style.backgroundColor = "black";

    let classes = [
        "WorkContent",
        "TextContent",
        props.data.size === 3 ? "third" 
        : props.data.size === 2 ? "half" 
        : props.data.size === 1 ? "full" : "",
    ]

    return (
        <div className={classes.join(" ")} style={style}>
            {props.data.text ? <h3>{props.data.title}</h3>
            : <h1>{props.data.title}<hr/></h1>}
            {props.data.text && typeof props.data.text === "string" 
            ? <p>{props.data.text}</p> 
            : props.data.text && Array.isArray(props.data.text) ? <ul> {props.data.text.map((obj, idx)=>{return <li key={idx}>{obj}</li>})}</ul> : null}
        </div>
    )
}

export default textContent;