//import Libraries
import React from 'react';

//import Components

//import Style classes
import "./ImageContent.css";

const imageContent = (props) => {
    const style = { };
    if(props.data.height) style.height = props.data.height;
    let classes = [
        "WorkContent",
        "ImageContent",
        props.data.size === 3 ? "third" 
        : props.data.size === 2 ? "half" 
        : props.data.size === 1 ? "full" : "",
    ]

    return (
        <div className={classes.join(" ")} style={style}>
            <img src={require("../../assets/works/"+props.link+"/"+props.data.path)} alt=""/>
        </div>
    )
}

export default imageContent;
