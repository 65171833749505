//import Libraries
import React from 'react';

//import Components
import ImageSlider from '../ImageSlider/ImageSlider';

//import Style classes
import "./ImageSliderContent.css";

const imageSliderContent = (props) => {
    const style = {
        
    };
    // if(props.data.height) style.height = props.data.height;
    
    let classes = [
        "WorkContent",
        "ImageSliderContent",
        props.data.size === 3 ? "third" 
        : props.data.size === 2 ? "half" 
        : props.data.size === 1 ? "full" : null,
        props.data.ratio ? props.data.ratio : null
    ]

    return (
        <div className={classes.join(" ")} style={style}>
            <ImageSlider auto={props.data.auto === true ? true : false}>
                {props.data.path.map((obj, idx)=>{
                    return <img key={idx} src={require("../../assets/works/"+props.link+"/"+obj)} alt=""></img>
                })}
            </ImageSlider>
        </div>
    )
}

export default imageSliderContent;
