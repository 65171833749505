//import Libraries
import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

//import Components
import Layout from '../../hoc/Layout/Layout';
import WorkCard from '../../components/WorkCard/WorkCard';
import WorkPage from './WorkPage/WorkPage';

import worksData from '../../data/workdata';
// const worksData = 0;

//import Style classes

class Works extends Component {

    componentDidMount() {
        // document.title = "Works - Wayway Studio";
    }

    render(){
        if(this.props.match.params.work && worksData[this.props.match.params.work]){
            let data = worksData[this.props.match.params.work];
            console.log(data)
            console.log(this.props.match.params.work)
            //Detail Page for Game
            return(
                <Layout fullpage={true}>
                    <Helmet>
                        <title>{data.title+" - Wayway Studio"}</title>
                        <meta name="description" content={data.shortDescription} />
                    </Helmet>
                    <WorkPage data={data} link={this.props.match.params.work}/>
                </Layout>
            )
        }else{
            // All works
            return (
                <Layout fullpage={true}>
                    <Helmet>
                        <title>{"Works - Wayway Studio"}</title>
                    </Helmet>
                    {Object.keys(worksData).map((key, idx)=>{
                        let data = worksData[key]
                        return(
                            <WorkCard fullpage={true} 
                                title={data.title}
                                link={key} 
                                data={data} key={idx}></WorkCard>
                        )
                    })}
                </Layout>
            )
        }
    }
}

export default Works;
