const worksData = {
    aksun: {
        title: "Aksun",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "upcoming",
        released: {
            Steam: null,
        },
        releaseDate: "2025",
        upcoming: {
            Steam: "https://store.steampowered.com/app/3129360/"
        },
        upcomingDate: "In Development",
        logo: "baseLogo.png",
        icon: null,
        price: "TBD",
        shortDescription: "Occult Action Rougelike",
        page: [
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/KOTyeDxhdkM"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "image",
                size: 1,
                path: "main-001.jpg"
            },
            {
                type: "image",
                size: 1,
                path: "title-typo.jpg"
            },
            {
                type: "image",
                size: 2,
                path: "SS01.jpg"
            },
            {
                type: "text",
                title: "Action Rougelike \nIn the mystic Underworld",
                size: 2,
                text: "Aksun  expands on its initial premise by taking players on an immersive journey through the Afterlife, as defined by Korean shamanic folklore. A soul burdened with unresolved earthly ties, players are thrust into the ethereal world, where the line between life and death blurs. Guided by the enigmatic Heir of East Sea, you navigate the perilous River of Three Paths, battling ferocious demons and uncovering hidden truths that challenge the very fabric of reality."
            },
            {
                type: "text",
                title: "Fresh Gameplay Mechanics \nDeep Narrative",
                size: 2,
                text: "This rogue-like dungeon crawler uniquely combines traditional action-packed gameplay with a deep narrative. Each run through the dungeons not only tests your combat skills but also reveals more about his life, his untimely demise, and the secrets that tether him to the afterlife. The game features an innovative mechanic that blends real-time combat with strategic use of abilities, allowing for a dynamic and engaging player experience."
            },
            {
                type: "image",
                size: 2,
                path: "SS02.jpg"
            },
            {
                type: "image",
                size: 2,
                path: "SS03.jpg"
            },
            {
                type: "text",
                title: "Dynamic Gameplay \nReplayability",
                size: 2,
                text: "The world of Aksun is richly detailed, drawing from the wealth of Korean mythology to create a hauntingly beautiful afterlife filled with both malevolence and melancholy. As players delve deeper, they encounter various characters, each with their own stories that intertwine with his fate. The ultimate goal is not just to escape the afterlife but to achieve liberation for his soul, resolving his earthly regrets and uncovering the truth behind his mother Anyana's mysterious involvement in the deeper conspiracies of the afterlife."
            },
            // {
            //     type: "video",
            //     size: 1,
            //     link: "https://www.youtube.com/embed/4Jcn_JF0N-Q"
            // },
        ]
    },
    kittypoptouch: {
        title: "Kitty Pop Touch",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "upcoming",
        released: {
            AppStore: null,
            GooglePlay: null,
            kongregate: null
        },
        releaseDate: "",
        upcoming: {
            AppStore: "javascript:alert('In Alpha Test')",
            GooglePlay: "javascript:alert('In Alpha Test')",
        },
        upcomingDate: "In Alpha Test",
        logo: "baseLogo.png",
        icon: null,
        price: "Free (In-app purchase)",
        shortDescription: "Touch, Match, Meow",
        page: [
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/2ePaBdpIUOw"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "text",
                title: "A New Take in \nTouch Match Puzzles",
                size: 2,
                text: "Just Touch any Block\nPop Pop Pop\nJust have Relaxing Fun"
            },
            {
                type: "text",
                title: "Play Puzzles \nManage Your Own Cat Home",
                size: 2,
                text: "Get more Coins to\nGet more Cats\nExpand your Cat House"
            },
            {
                type: "image",
                size: 3,
                path: "002.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "005.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "006.jpg"
            },
            {
                type: "text",
                title: "Touch",
                size: 3,
                text: "🧱 Mechanics that don't need Tutorials\n🧱 Touch and Pop Cats\n🧱 The Single Simple Interaction"
            },
            {
                type: "text",
                title: "Pop",
                size: 3,
                text: "🧱 Pop Pop Pop!\n🧱 Casual and Relaxing Poppin Cats\n🧱 Swoosh, Pang, Clash and Pop"
            },
            {
                type: "text",
                title: "Meow",
                size: 3,
                text: "🧱 Various Types of Cats to Unveil\n🧱 Expand your Cat House along your adventure\n🧱 Decorate your Comfy Cat Home"
            },
            {
                type: "image",
                size: 3,
                path: "007.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "008.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "009.jpg"
            },
            {
                type: "text",
                title: "External Alpha Test Ongoing",
                size: 1,
            },
            {
                type: "image",
                size: 1,
                path: "017.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "010.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "011.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "012.jpg"
            },
        ]
    },
    projectd: {
        title: "Toy Squad TD",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "upcoming",
        released: {
            AppStore: "javascript:alert('Release on 2021 Fall')",
            GooglePlay: "javascript:alert('Release on 2021 Fall')",
            kongregate: null
        },
        releaseDate: "",
        upcoming: {
            AppStore: "javascript:alert('Release on 2021 Fall')",
            GooglePlay: "javascript:alert('Release on 2021 Fall')",
        },
        upcomingDate: "2021 Fall",
        logo: "baseLogo.png",
        icon: null,
        price: "Free (In-app purchase)",
        shortDescription: "World of Toys",
        page: [
            {
                type: "image",
                size: 1,
                path: "image-01.jpg"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "text",
                title: "A New Take in \nMobile Tower Defense Genre",
                size: 2,
                text: "Your Toys have to defend the area\nGet Gold and get new Toyboxes\nBuild more towers to win"
            },
            {
                type: "text",
                title: "More Mechanics \nBut Casual Game Play",
                size: 2,
                text: "Your Toys have to defend the area\nGet Gold and get new Toyboxes\nBuild more towers to win"
            },
            {
                type: "image",
                size: 3,
                path: "screenshot-01.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "screenshot-02.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "screenshot-03.jpg"
            },
            {
                type: "text",
                title: "Toys are Alive!",
                size: 3,
                text: "🧱 Your Toys have to defend the area\n🧱 Get Gold and get new Toyboxes\n🧱 Build more towers to win"
            },
            {
                type: "text",
                title: "Merge Your Towers",
                size: 3,
                text: "🧱 Merge Towers for Level Up!\n🧱 Collect Energy to Upgrade your Base Merge Level\n🧱 Squad Levels are beneficial to all Toys of same Division"
            },
            {
                type: "text",
                title: "Discover New Toys",
                size: 3,
                text: "🧱 Various Types of Towers are hidden\n🧱 Find new Towers along your adventure\n🧱 Get New Types of Toys from the same box"
            },
            {
                type: "text",
                title: "Division #1 : Robo",
                size: 1,
            },
            {
                type: "image",
                size: 1,
                path: "01_Robot.jpg"
            },
                        {
                type: "text",
                title: "Division #2 : Bunny",
                size: 1,
            },
            {
                type: "image",
                size: 1,
                path: "02_Bunny.jpg"
            },
                        {
                type: "text",
                title: "Division #3 : Slimey",
                size: 1,
            },
            {
                type: "image",
                size: 1,
                path: "03_Slime.jpg"
            },
                        {
                type: "text",
                title: "Division #4 : Blocky",
                size: 1,
            },
            {
                type: "image",
                size: 1,
                path: "04_Block.jpg"
            },
        ]
    },
    magnolia: {
        title: "Magnolia",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "experimental",
        released: {
            AppStore: "",
            GooglePlay: "",
            kongregate: null
        },
        releaseDate: "",
        upcoming: {
            AppStore: "javascript:alert('Release on 2020')",
            GooglePlay: "https://play.google.com/store/apps/details?id=com.Wayway.Magnolia",
        },
        upcomingDate: "2020",
        logo: "baseLogo.png",
        icon: null,
        price: "Free (In-app purchase)",
        shortDescription: "The Bee Garden",
        page: [
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/k88BNZr2WN4"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "text",
                title: "Grow Your Own Garden",
                size: 2,
                text: "✻ You are the Queen Bee of this Garden\n✻ Get Honey from Flowers to Seed new Flowers\n✻ Go on Explorations to Unlock more Flowers and Gardens\n\n✻ Unique Flowers are ready to be reveiled\n✻ Flowers from your Favorite to the Mystic"
            },
            {
                type: "text",
                title: " ",
                size: 2,
                text: "It was a sunny bright evening, an evening so calm,\nThe kind of evening that was inviting me with an outstretched arm.\nGetting up from my chair, I thought I'd take a stride\nThen there was a bumble bee that suddenly came by my side.\nThere was a kind of music as the bee flapped its wing,\nMusic so perfect that no one could ever sing.\n\nPearlyn, <An Evening Hour>"
            },
            {
                type: "image",
                size: 3,
                path: "images-01.png"
            },
            {
                type: "image",
                size: 3,
                path: "images-02.png"
            },
            {
                type: "image",
                size: 3,
                path: "images-03.png"
            },
            {
                type: "text",
                title: "Grow Your Garden",
                size: 3,
                text: "You are the Queen Bee\nMake Worker Bees, Seed Flowers, Get Honey\nGo on Explorations"
            },
            {
                type: "text",
                title: "Collect Honey from your Flower Garden",
                size: 3,
                text: "You can collect honey from bloomed flowers\nUpgrade your flowers to get more Bloom Bonus!\nOpen New Flower Beds to seed New Flowers"
            },
            {
                type: "text",
                title: "Get More Honey Bees",
                size: 3,
                text: "You can get more Honey Bees with Honey\nBees will then get you more Honey\n Honey then get you more Bees!"
            },
            {
                type: "image",
                size: 3,
                path: "images-04.png"
            },
            {
                type: "image",
                size: 3,
                path: "images-05.png"
            },
            {
                type: "image",
                size: 3,
                path: "images-06.png"
            },
            {
                type: "text",
                title: "Collect New Flowers",
                size: 3,
                text: "Hundreds of Flowers are waiting to be revealed\nWhat is your favorite flower?\nBet you can find it!"
            },
            {
                type: "text",
                title: "Go on Explorations",
                size: 3,
                text: "Exploration can reveal New Lands,\nNew Flowers, More Honey, More Pollen\nExplore to Find New Things"
            },
            {
                type: "text",
                title: "Find New Things",
                size: 3,
                text: "Exploration is the Essense to find new things\nExploration Bees will help you\nfind more resources."
            },
        ]
    },
    planet2049: {
        title: "Planet 2049",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "released",
        released: {
            AppStore: "https://apps.apple.com/app/planet-2049/id1535912545",
            GooglePlay: "https://play.google.com/store/apps/details?id=com.wayway.projectt",
            kongregate: null
        },
        releaseDate: "",
        upcoming: {
            AppStore: "javascript:alert('Release on October 2020')",
            GooglePlay: "javascript:alert('Release on October 2020')",
        },
        upcomingDate: "2020",
        logo: "baseLogo.png",
        icon: null,
        price: "Free",
        shortDescription: "Retro Action Block Puzzle",
        page: [
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/ZZKLt47VzBc"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "image",
                size: 3,
                path: "SS_01.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "SS_02.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "SS_03.jpg"
            },
            {
                type: "text",
                title: "Game Description",
                size: 2,
                text: "- Planet 2049 is an Action Puzzle Game. \n- Move Blocks in 4 directions to fill a rows or columns and Clear Lines. \n- 21st century version of the Game-Logic Based on the Legendary Game 'Tetris'\n- As simple as the Classic 2048 block puzzle game."
            },
            {
                type: "text",
                title: "Features",
                size: 2,
                text: "- Easy Rules : Your granma👵 can play Planet 2049!\n- Easy Interactions: Swipe↕↔\n- Time Attack: Action Puzzle 🧩🧩\n- Global Ranking 🌐\n- Pixel Art Stylized 👾\n- 8bit Style Music and Sound Effects"
            },
            {
                type: "image",
                size: 3,
                path: "SS_04.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "SS_05.jpg"
            },
            {
                type: "image",
                size: 3,
                path: "SS_06.jpg"
            },
            {
                type: "text",
                title: "Background",
                size: 2,
                text: "Year 2049... 🌎Earth is now almost completely depleted🏭\nEarthlings have a plan to ♻️recycle their way out of this crisis...\nPlay and recycle resources to bring back Earth to Life.\nMake Earth Great Again 🤟🚀"
            },
            {
                type: "text",
                title: "How to Play",
                size: 2,
                text: "- GRID MAP : ▦ 7 X 7 \n - SWIPE - ↕up, down ↔ left, right \n- CLEAR 🧱 Blocks  by Filling rows or column ☄☄☄\n- TIME LIMIT : ⏱30 seconds"
            }
        ]
    },
    // projectg: {
    //     title: "Project G",
    //     backgroundImage: "background.jpg",
    //     backgroundColor: null,
    //     status: "experimetal",
    //     released: {
    //         AppStore: null,
    //         GooglePlay: null,
    //         kongregate: null,
    //         Steam: ""
    //     },
    //     releaseDate: "",
    //     upcoming: {
    //         Steam: "javascript:alert('Release on 2021')",
    //     },
    //     upcomingDate: "2021",
    //     logo: "baseLogo.png",
    //     icon: null,
    //     price: "$9.99",
    //     shortDescription: "A Tale of the Far East",
    //     page: [
    //         {
    //             type: "imageSlider",
    //             size: 1,
    //             ratio: "hd",
    //             path: [
    //                 "example-00.jpg",
    //                 "example-01.jpg",
    //                 "example-02.jpg",
    //             ],
    //         },
    //         {
    //             type: "title",
    //             size: 1
    //         },
    //         {
    //             type: "text",
    //             title: "How far have you been to the east?",
    //             size: 1,
    //             text: "We are putting together a game truely from our region.\nGet ready to digest the wonders of Ancient Korea."
    //         }
    //     ]
    // },
    hexelectric: {
        title: "Hexelectric",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "released",
        released: {
            Steam: "https://store.steampowered.com/app/1093370/",
        },
        releaseDate: "27th June 2019",
        upcoming: {
            Steam: "https://store.steampowered.com/app/1093370/",
        },
        upcomingDate: "June 2019",
        logo: "baseLogo.png",
        icon: null,
        price: "$2.99",
        shortDescription: "Mind Twisting Puzzler",
        page: [
            {
                type: "imageSlider",
                size: 1,
                path: [
                    "SS1.jpg",
                    "SS2.jpg",
                    "SS3.jpg",
                    "SS4.png",
                    "SS5.png",
                    "SS6.png",
                    "SS7.png",
                    "SS8.png",
                    "SS9.png",
                    "SS10.jpg"
                ],
                ratio: "hd",
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "image",
                size: 2,
                path: "background.jpg"
            },
            {
                type: "text",
                title: "A space man is Lost in Space",
                size: 2,
                text: "Fix all the engine parts and head back home. This is a puzzle game with a space theme. Rotate hex-tiles and match the target with the right amount of energy. It's easy to learn and fun to play.\n\n We are preparing a demo version release on Kongregate so that players who want to have a sneakpeak can do so.\n\n Thank you for all the attention and Good Luck."
            },
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/nf-v0dphxD0"
            },
            {
                type: "text",
                title: "150+ Hand-crafted stages",
                size: 3,
                text: "Over 150 stages this is not just a random hex-tile game. It's made to tease your brain. Make you feel excited while playing."
            },
            {
                type: "text",
                title: "13 Unique Chapters",
                size: 3,
                text: "Each chapter has unique puzzle logic and gradually adding on more. When you go into end chapters all the logic will be combined to pleasure your creative mind."
            },
            {
                type: "text",
                title: "Original Soundtrack",
                size: 3,
                text: "We make our own music! Enhancing the space theme of the game, this time we come back with a variety of cybertic mechanic soundtracks"
            },
            // {
            //     type: "link",
            //     title: "Which one is better?",
            //     text: "Choose which design is better and let us know",
            //     size: 1,
            //     background: "/works/hexelectric/compare-01.jpg",
            //     link: {
            //         type: "external",
            //         address: "https://goo.gl/forms/kKyHdB7KpOfc8oWH2"
            //     }
            // }
        ]
    },
    rowrow: {
        title: "RowRow",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "released",
        released: {
            Steam: "https://store.steampowered.com/app/1001200/ROWROW/",
            AppStore: "https://itunes.apple.com/kr/app/wayways-rowrow/id1448010249?mt=8",
            GooglePlay: "https://play.google.com/store/apps/details?id=com.waywaystudio.rowrow"
        },
        releaseDate: "31th Jan 2019",
        upcoming: {
            Steam: "javascript:alert('Release on 31st Jan 2019')",
            AppStore: "javascript:alert('Release on March 2019')",
            Googleplay: "javascript:alert('Release on 31st Jan 2019')",
        },
        upcomingDate: "Jan 2019",
        logo: "baseLogo.png",
        icon: null,
        shortDescription: "Relaxing Puzzle Game",
        price: "0.99$",
        page: [
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/6h9V2LenQ4s"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "image",
                size: 2,
                path: "tutorial-01.jpg"
            },
            {
                type: "text",
                title: "Description",
                size: 2,
                text: "“RowRow” is a unique sliding puzzle game with more than 100 levels of hand crafted stages and diverse puzzle logics. It is as simple as matching shapes. Align rows of shapes, sum them up and match the border-lined shapes to clear a stage.\n\nThis could easily be a math game. But with the twist of the 'Shapes = Numbers' concept, it brings more simplicity and adds more aesthetic to the game. And along the way to the final stage you will meet a few intersting logics too. Using the 'Sliding' mechanism in an unprecedented method these transfiguration will sometimes make you reminisce of some traditional sliding puzzle games with a new collaboration of mathematical operations."
            },
            {
                type: "link",
                title: "Play DEMO with RowRow Lite - Google Play",
                text: "Play the Demo version with 40 stages! If you like it buy the extended version!",
                size: 1,
                background: "/works/rowrow/rowrowlite_background.jpg",
                link: {
                    type: "external",
                    address: "https://play.google.com/store/apps/details?id=com.waywaystudio.rowrow_free"
                }
            },
            {
                type: "image",
                size: 2,
                path: "background.jpg",
            },
            {
                type: "text",
                title: null,
                size: 2,
                text: "Although the mechanics of the game are simple, this game is NOT an easy game. as you go up the level. To some players it will take 10 minutes or more to clear one stage. The challenging stages are made with more caution and precision so that players looking for an intense puzzle experience would be satisfied. We challenge you to clear the hardest stage, 100th.\n\nYes, it does have an answer."
            },
            {
                type: "text",
                title: "Features",
                size: 1,
            },
            {
                type: "image",
                size: 2,
                path: "gameplay-02.gif"
            },
            {
                type: "image",
                size: 2,
                path: "gameplay-01.gif"
            },
            {
                type: "text",
                title: "Shapes = Numbers",
                size: 3,
                text: "RowRow was actually a mini game inside a 3D Number Sequence Puzzle Game we were making called 'SQNCE'.\n\nSo it naturally inherited the mathematical side of the former game. Math can be boring, tiring and annoying. We wanted to give a relaxing experience that was at the same time somewhat challenging and puzzling. \n\nHenceforth comes the twist of the 'Shapes = Numbers' concept, it brungs more simplicity and adds more aesthetic to the game. "
            },
            {
                type: "text",
                title: "Minimalistic Design",
                size: 3,
                text: "The minimalistic shapes naturally gave us simplicity. So everything else in the game had to be as minimal as possible. \n\nColor: The beige color and the tone-down black gave RowRow the feeling of ink upon paper.\n\nAlthough the visuals of the game is simple the information it gives is not bland. It gives you what numbers are correct and what are not, what the sum is calculated as in each shape and in what direction each objective shape is adding the shapes."
            },
            {
                type: "text",
                title: "Unique Mechanics",
                size: 3,
                text: "Slinding, Adding, Blocking\n\n'Sliding' mechanism will make you reminisce of some traditional sliding puzzle games. \n\n'Adding' numbers to the traditional sliding puzzle game gives this game depth and difficulty.\n\n'Blocking' blocks make this game more interesting, dynamic change how a player think can be challenged to more difficult challenges."
            },
            {
                type: "text",
                title: "Screenshots",
                size: 1,
            },
            {
                type: "imageSlider",
                size: 1,
                height: "calc(56.25vw - 337.5px)",
                path: [
                    "screenshot-01.jpg",
                    "screenshot-04.jpg",
                    "screenshot-07.jpg",
                    "screenshot-08.jpg",
                    "screenshot-09.jpg",
                    "screenshot-11.jpg"
                ],
                ratio: "hd",
                auto: true
            },
            {
                type: "text",
                title: "Reviews & Awards",
                size: 1,
            },
            {
                type: "text",
                size: 2,
                title: "User Reviews",
                text: 
                    "This game's mechanic seems really cool! - death2cupbots\n\nSimple, clever and difficult. It raises a sense of challenge. - greei\n\nWow, What an original game. One of the best puzzle games on this page that got me playing it all. - Rixxi10"
                
            },
            {
                type: "text",
                size: 2,
                title: "Kongregate Monthly Contest Winner - Jan 2019",
                text: "Thanks to all the reviewers\n Protype version of RowRow won fourth place\n in the monthly contest on Kongregate\n\nThis Successful pitch led us to other platforms"
            }
        ]
    },
    rgbit: {
        title: "RGBit",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "released",
        released: {
            AppStore: "https://itunes.apple.com/kr/app/rgbit-color-mixing-game/id1458838311?mt=8",
            GooglePlay: "https://play.google.com/store/apps/details?id=com.waywaystudio.rgbit_mixing",
            kongregate: null
        },
        releaseDate: "14th March 2019",
        upcoming: {
            AppStore: "javascript:alert('Release on Mid March 2019')",
            GooglePlay: "javascript:alert('Release on Mid March 2019')",
        },
        upcomingDate: "March 2019",
        logo: "baseLogo.png",
        icon: null,
        price: "Free (In-app purchase)",
        shortDescription: "Color Mixing Game",
        page: [
            {
                type: "image",
                size: 1,
                path: "background.jpg"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "imageSlider",
                size: 2,
                ratio: "hd",
                path: [
                    "tutorial-01.jpg",
                    "tutorial-02.jpg",
                    "tutorial-03.jpg",
                    "tutorial-04.jpg"
                ],
            },
            {
                type: "text",
                title: "Mix it & Match it",
                size: 2,
                text: "߹ Are you a Color Mixer?\n߹ How fast can you match a color?\n߹ Well, let's find out!\n\nRGBit is a perfect game for Everyone who likes colors and also Designers and Artists who deal with selecting colors to create images, drawings. You can train continuously to improve your skills with RGB, CMYK, HSB digital color spaces which are not quite intuitive for us humans who have been mixing colors with paint for the majority of our history.\n\nWhile drawing an Image, selecting colors always need a lot of effort to generate what you had in mind. Our tutorials and statistics feature will provide you where you are good and in what color space you are not. Mix it! RGB it!\n\nAnyone who has the color-mind will create the color-ful.."
            },
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/0bI-cokdZNI"
            },
            {
                type: "text",
                title: "Features",
                size: 1,
            },
            {
                type: "text",
                size: 3,
                title: "3 Different Modes",
                text: "We give you 3 options of mixing color modes, RGB / CMYK / HSB those you can commonly see in Adobe applications. We hope this variation will give you more understanding in color spaces and and help you improve flawless mixing colors."
            },
            {
                type: "text",
                size: 3,
                title: "Detailed Statistics",
                text: "To improve your skills you would have to know excatly where you miss or in what color you thrive. We make this possible by detailed statistics. Results of every round will be stored as average time spent in each modes and color categories."
            },
            {
                type: "text",
                size: 3,
                title: "Achievements & Scoreboards",
                text: "Your average score will be shared with others. Compete with all the color mixers all around the world. Get trophies and achievements!"
            },
            {
                type: "imageSlider",
                size: 3,
                height: "calc(59.2vw - 355.55px)",
                ratio: "vertical",
                path: [
                    "mode-02.jpg",
                    "mode-03.jpg",
                    "mode-04.jpg",
                    "mode-01.jpg"
                ]
            },
            {
                type: "imageSlider",
                size: 3,
                height: "calc(59.2vw - 355.55px)",
                ratio: "vertical",
                path: [
                    "stats-01.jpg",
                    "stats-02.jpg",
                    "stats-03.jpg",
                    "stats-04.jpg"
                ]
            },
            {
                type: "imageSlider",
                size: 3,
                height: "calc(59.2vw - 355.55px)",
                ratio: "vertical",
                path: [
                    "trophy-01.jpg",
                    "trophy-02.jpg"
                ]
            },
            {
                type: "link",
                title: "Color Experiment Application",
                text: "This is a simple random color generator used to distinguish certain ranges that RGBit needed to give as the target color of each rounds.",
                size: 1,
                background: "/special/color-experiment/background.jpg",
                link: {
                    type: "internal",
                    address: "/works/rgbit/color-experiment"
                }
            }
        ]
    },
    sqnce: {
        title: "SQNCE",
        backgroundImage: "background.jpg",
        backgroundColor: null,
        status: "experimental",
        released: {
            Steam: "link",
            Appstore: "link",
            Googleplay: "link",
            Kongregate: null
        },
        upcoming: {
            steam: "date",
            appstore: "date",
            googleplay: "date",
            all: "AllDate"
        },
        logo: null,
        icon: null,
        shortDescription: "Experimental Number Sequence Puzzler",
        page: [
            {
                type: "video",
                size: 1,
                link: "https://www.youtube.com/embed/9eD-WC_5ry8"
            },
            {
                type: "title",
                size: 1
            },
            {
                type: "imageSlider",
                size: 2,
                ratio: "hd",
                path: [
                    "example-01.jpg",
                    "example-03.jpg",
                    "example-04.jpg",
                    "example-05.jpg",
                    "example-06.jpg",
                    "example-07.jpg"
                ]
            },
            {
                type: "text",
                title: "Description",
                size: 2,
                text: "This project was a highly anticipated experimental game. Conveying possibly boring math sequence to a game was chanllenging enough, but we went one step further to make multiple small games inside one. Although it seems easy, this game tries to make you understand complex ideas of number sequences.\n\n This is not an on going project. \nBut maybe we will make a public prototype version.\n\n Maybe not."
            }
        ]
    },

}

export default worksData;