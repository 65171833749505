//import Libraries
import React, {Component} from 'react';

//import Components
import VideoContent from '../../../components/VideoContent/VideoContent';
import ImageContent from '../../../components/ImageContent/ImageContent';
import ImageSliderContent from '../../../components/ImageSliderContent/ImageSliderContent';
import TextContent from '../../../components/TextContext/TextContent';
import TitleContent from '../../../components/TitleContent/TitleContent';
import LinkContent from '../../../components/LinkContent/LinkContent';
import Factsheet from '../../../components/Factsheet/Factsheet';
import WorkCard from '../../../components/WorkCard/WorkCard';

import workData from '../../../data/workdata';

//import Style classes
import './WorkPage.css';

class Workpage extends Component {
    componentDidMount() {
      
    }
    dataHandler = (data, idx) => {
        switch(data.type) {
            case "video":
                return <VideoContent data={data} key={idx} link={this.props.link}/>
            case "image":
                return <ImageContent data={data} key={idx} link={this.props.link}/>
            case "imageSlider":
              return <ImageSliderContent data={data} key={idx} link={this.props.link}/>
            case "text":
              return <TextContent data={data} key={idx} link={this.props.link}/>
            case "title":
              return <TitleContent data={data} key={idx} link={this.props.link}/>
            case "link":
              return <LinkContent data={data} key={idx} link={this.props.link}/>
            default:
              return <div key={idx}>type : text</div>
          }
    }

    render(){
        return (
            <div className="WorkPage">
              
              <div className="WorkPageContent">
                {this.props.data.page.map((obj, idx)=>{
                  return this.dataHandler(obj, idx)
                })}
                <div className="OtherWorks">
                  <TextContent data={{type: "text", title: "Other Works"}}/>
                  {Object.keys(workData).map((key, idx)=>{
                    if(key !== this.props.link){
                      return (
                        <WorkCard fullpage={true} 
                          inside={true}
                          title={workData[key].title}
                          link={key} 
                          data={workData[key]} key={idx}></WorkCard>
                      )
                    }
                    return null;
                  })}
                </div>
              </div>
              <Factsheet data={this.props.data}>
                {this.props.data.status === "experimental" ? <p><strong>This is an experimental project<br/>not for sale</strong></p> : null}
              </Factsheet>
            </div>
        )
    }
}

export default Workpage;
