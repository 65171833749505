//import Libraries
import React from 'react';

//import Components


//import Style classes

const googlePlay = (props) => {

    return (
        <div class="PromotionInstructions">
            <h3>iPhone, iPod touch 에서</h3>
            <div>
                <ol>
                    <li>iPhone, iPad 또는 iPod touch에서 App Store 앱을 엽니다.</li>
                    <li>'투데이'를 탭한 다음 오른쪽 상단 모서리에 있는 <img src="https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/collection/build/ionicons/svg/ios-person.svg" width="22" alt="나의 계정 버튼" className="svg"/> 또는 사진을 탭합니다.</li>
                    <li>'기프트 카드 또는 코드 교환'을 탭한 후 Apple ID로 로그인합니다. <a href="https://support.apple.com/ko-kr/HT201354">Apple ID를 잊어버린 경우</a> 수행할 작업에 대해 알아봅니다.&nbsp;</li>
                    <li>iPhone, iPad 또는 iPod touch에서 카메라를 사용하여 기프트 카드를 추가하려면 '카메라 사용하기'를 탭합니다. 화면의 안내에 따라 사용권 코드를 캡처해야 합니다.<br/>
                    특정 국가 또는 지역에 있거나 이전 버전의 카드를 가지고 있는 경우에는 카드를 수동으로 입력해야 할 수 있습니다. "코드를 직접 입력할 수도 있습니다."를 탭한 후 표시되는 메시지를 따릅니다.
                        <ul>
                            <li>App Store 및 iTunes 기프트 카드가 있는 경우 <a href="#locate_code">카드 뒷면에 있는</a> X로 시작하는 16자리 코드를 입력합니다.</li>
                            <li>프로모션 코드나 콘텐츠 코드가 있는 경우 카드 뒷면에 있는 코드를 입력합니다.</li>
                        </ul>
                    </li>
                    <li>'완료'를 탭합니다.</li>
                </ol>
                <p>경고 메시지가 표시되거나 <a href="https://support.apple.com/ko-kr/HT201195">App Store 및 iTunes 기프트 카드,&nbsp;Apple Music 기프트 카드 또는 콘텐츠 코드를 사용할 수 없는 경우</a> 수행할 작업에 대해 알아봅니다.</p>
            </div>
            <hr/>
            <div>
                <ol>
                    <li>On your iPhone, or iPod touch,&nbsp;open the App Store app.</li>
                    <li>Tap Today, then tap&nbsp;<img src="https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/collection/build/ionicons/svg/ios-person.svg" width="22" alt="the My Account button" className="svg"/>&nbsp;or your photo in the upper-right corner.</li>
                    <li>Tap "Redeem Gift Card or Code," then sign in with your Apple ID. Learn what to do <a href="https://support.apple.com/kb/HT201354">if you forgot your Apple ID</a>.&nbsp;</li>
                    <li>If you want to use the camera in your iPhone, iPad, or iPod touch to add your gift card, tap Use Camera. Make sure to follow the onscreen instructions to capture the redemption code.<br/>
                    If you're in certain countries or regions, or have an older card, you might have to enter the card manually: Tap "You can also enter your code manually," then follow the prompts.<ul>
                    <li>If you have an App Store &amp; iTunes Gift Card,&nbsp;enter the 16-digit code, which starts with X, <a href="#locate_code">from the back of the card</a>.</li>
                    <li>If you have a promo or content code, enter the code from the back of the card.</li>
                    </ul>
                    </li>
                    <li>Tap Done.</li>
                    </ol>
                    <p>Learn what to do if you see an alert message or <a href="https://support.apple.com/kb/HT201195">can't redeem your App Store &amp; iTunes Gift Card,&nbsp;Apple Music Gift Card, or content code</a>.</p>
            </div>
            <img src={require('../../../assets/special/promotion-instruction/app-store-promotion-code.jpg')} alt="App Store Promotion Code Usage"/>
        </div>
    )
}

export default googlePlay;
