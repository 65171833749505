//import Libraries
import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from '../../axios-submit';

//import Components


//import Style classes
import './Navigation.css';
import logo from '../../assets/logo/main-logo';
import icons from '../../assets/icons/icons';

class Navigation extends Component {

    state = {
        loading: true,
        open: false,
        email: ""
    }

    menuToggle = (e) => {
        this.setState((prevState)=>{
            return{
                open: !prevState.open
            }
        })
    }

    submitEmail = (e) => {
        e.preventDefault()
        axios.post('/subscribers.json', {email : this.state.email})
        .then(response => window.alert("Submission Successful!\nThank you for your submission.\nWe will be in touch."))
        .catch(error => window.alert("Submission Failed!\nCheck your email address"));
    }

    emailChangeHandler = (e)=>{
        if(e.target.value !== this.state.email){
            this.setState({
                email: e.target.value
            })
        }
    }

    componentDidMount() {
        setTimeout(()=>{
            this.setState({loading: false});
        }, 2000)
    }

    render() {
        let addClass = "";
        if(this.state.loading === true) addClass = " Loading";
        if(this.state.open === true) addClass += " open";

        return (
            <div className={'Navigation'+addClass}>
                <div className="HomeLink">
                    <Link to='/'>
                        {logo}
                        <p className="LogoTitle">Wayway Studio</p>
                    </Link>
                </div>
                <div className="ReponsiveTitle"><a href="/">{"Wayway Studio"}</a></div>
                <div className="ResponsiveMenu" onClick={this.menuToggle}>
                    <div className="MenuStick"></div>
                    <div className="MenuStick"></div>
                    <div className="MenuStick"></div>
                </div>
                <div className="NavList">
                    <nav>
                        <ul>
                            <li><NavLink to='/'>Home<div></div></NavLink>
                                <div className='NavUnderline'>
                                    <div></div>
                                </div>
                            </li>
                            <li><NavLink to='/news'>News<div></div></NavLink>
                                <div className='NavUnderline'>
                                    <div></div>
                                </div>
                            </li>
                            <li><NavLink to='/works'>Games<div></div></NavLink>
                                <div className='NavUnderline'>
                                    <div></div>
                                </div>
                            </li>
                            <li><NavLink to='/about'>About us<div></div></NavLink>
                                <div className='NavUnderline'>
                                    <div></div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="SubscribeModule">
                    <h3>Subscribe</h3>
                    <form onSubmit={this.submitEmail}>
                        <input id="emailAddress" type="email" placeholder="email@site.com" onChange={this.emailChangeHandler}></input>
                        <button id="Subscribe" type="submit">Submit</button>
                    </form>
                    <p>{"We will send you emails \nfor release updates and news."}</p>
                </div>
                <div className="SocialNetwork">
                    <div className="SocialIcons">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuVD12lB2EMzZiA6d57ZfWA">{icons.youtube}</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/waywaystudio">{icons.twitter}</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/wayway_studio/">{icons.instagram}</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Wayway-Studio-1005643332955455/">{icons.facebook}</a>
                    </div>
                </div>
            </div>
        );
    }
}


export default Navigation;