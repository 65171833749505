//import Libraries
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

//import Components

import icons from '../../assets/icons/icons';

//import Styles
import './WorkCard.css';

let workImage;

class WorkCard extends Component{
    state = {
        title: this.props.data.title,
        status: this.props.data.status,
    }
    clickHandler = (e)=>{
        
    }
    render(){
        //Add Background
        if(this.props.data.backgroundImage){
            workImage = <img src={require("../../assets/works/"+this.props.link+"/"+this.props.data.backgroundImage)} alt=""/>
        }else if(this.props.data.backgroundColor){
            workImage = <div className="WorkColor" style={{backgroundColor: this.props.data.backgroundColor}}></div>
        }

        //Add release icons or add upcoming, experimental sign
        const statusImage = this.state.status === "released" ? "release-tag.png" : this.state.status === "upcoming" ? "upcoming-tag.png" : "experimental-tag.png"
        const statusTag = <img alt=""
                            src={require('../../assets/tags/'+statusImage)} />

        //if Released, get linked icons
        let platforms;
        if(this.state.status === "released"){
             platforms = Object.keys(this.props.data.released).map((key, idx)=>{
                 if(this.props.data.released[key] !== null){
                    return <a target="_blank" rel="noopener noreferrer" key={idx} href={this.props.data.released[key]}>{icons[key.toLowerCase()]}</a>;
                 }else return null;
            })
        }else if(this.state.status === "upcoming"){
            platforms = Object.keys(this.props.data.upcoming).map((key, idx)=>{
                if(this.props.data.upcoming[key] !== null){
                   return <a target="_blank" rel="noopener noreferrer" key={idx} href={this.props.data.upcoming[key]}>{icons[key.toLowerCase()]}</a>;
                }else return null;
           })
        }
        
        console.log(this.props.link);
        //Fullpage bool check
        let addClass = this.props.fullpage ? " Fullpage" : "";
        return(
            

            <div className={"WorkCard"+addClass}>
                <div className="WorkImage">
                    {workImage}
                </div>
                <Link to={this.props.inside ? this.props.link : "/works/"+this.props.link} className={"WorkContent"} onClick={this.props.inside ? ()=>{window.scrollTo(0, 0);}: null}>
                    <p className="Description">{this.props.data.shortDescription}</p>
                    <h1 className="Title">{this.props.title}</h1>
                </Link>
                <div className="Platforms">{platforms}</div>
                <div className="StatusTag">{statusTag}</div>
            </div>
        )
    }
}


export default WorkCard;