//import Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types';

//import Components


//import Style classes
import './ImageSlider.css';


class ImageSlider extends Component {
    state = {
        sliderPosition: 0,
        maxPosition: this.props.children.length,
        intervalId: null
    }
    
    autoSlide = (interval = 2000) => {
        setInterval(()=>{
            let length = this.props.children.length;
            let currentSlide = this.state.sliderPosition;
            let newSlide;
            if(currentSlide === length-1) {
                newSlide = 0;
            }else {
                newSlide = currentSlide+1;
            }
            this.setState({sliderPosition: newSlide});
        },interval);
    }

    clickHandler = (directionString) => {
        let length = this.props.children.length;
        let currentSlide = this.state.sliderPosition;
        let newSlide;
        if(directionString === "left"){
            if(currentSlide === 0) {
                console.log("currentSlide === 0", typeof newSlide)
                newSlide = length-1;
            }else {
                newSlide = currentSlide-1;
            }
        }else if(directionString === "right"){
            if(currentSlide === length-1) {
                newSlide = 0;
            }else {
                newSlide = currentSlide+1;
            }
        }
        this.setState({sliderPosition: newSlide});
    }
    componentDidMount = () => {
        if(this.props.auto) this.autoSlide(this.props.interval);
    };
    componentWillUnmount(){

    }

    render(){

        const sliderChild = this.props.children.map((obj, idx)=>{
            let positionType = "";
            let currentSlide = this.state.sliderPosition;
            let length = this.props.children.length;
            if(currentSlide === idx) {positionType = "current"
            }else if(currentSlide === idx+1 || ( currentSlide === 0 && length-1 === idx )){
                positionType = "prev";
            }else if(currentSlide === idx-1 || ( currentSlide === length-1 && idx === 0 )){
                positionType = "next";
            }
            return  <div className={"SliderChild "+positionType} key={idx}>
                        {obj}
                    </div>
        })

        const sliderButton = 
            <React.Fragment>
                <div className="SlideButton Left" onClick={()=>this.clickHandler("left")}>
                    <svg version="1.1" id="LeftIcon" x="0px" y="0px" width="595.279px" height="595.281px" viewBox="0 123.305 595.279 595.281"
                        enableBackground="new 0 123.305 595.279 595.281">
                        <path opacity="0.8" enableBackground="new" d="M595.28,420.948c0-164.383-133.258-297.641-297.64-297.641
                            c-164.384,0-297.642,133.258-297.641,297.641c0,164.382,133.259,297.64,297.641,297.64
                            C462.022,718.588,595.28,585.331,595.28,420.948z M116.064,417.901l171.168-146.035l12.98,15.215L158.863,407.676h305.864v20
                            H157.218l143.425,127.246l-13.272,14.961L116.064,417.901z"/>
                    </svg>
                </div>
                <div className="SlideButton Right" onClick={()=>this.clickHandler("right")}>
                    <svg version="1.1" id="RightIcon" x="0px" y="0px" width="595.279px" height="595.281px" viewBox="0 123.305 595.279 595.281"
                        enableBackground="new 0 123.305 595.279 595.281" >
                        <path opacity="0.8" enableBackground="new" d="M-0.001,420.944c0,164.383,133.258,297.641,297.64,297.641
                            c164.383,0,297.641-133.258,297.641-297.641c0-164.382-133.259-297.64-297.641-297.64C133.257,123.305-0.001,256.563-0.001,420.944z
                            M479.216,423.992L308.048,570.027l-12.98-15.215l141.35-120.596H130.553v-20h307.508L294.636,286.971l13.272-14.961
                            L479.216,423.992z"/>
                    </svg>
                </div>
            </React.Fragment>

        return(
            <div className={'ImageSlider'}>
                {sliderChild}
                {this.props.children.length > 1 ? sliderButton : null}
            </div>
        );
    }
}

export default ImageSlider;

ImageSlider.propTypes = {
    auto: PropTypes.bool,
    interval: PropTypes.number,
    slideButton: PropTypes.bool
  };
