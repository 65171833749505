//import Libraries
import React from 'react';

//import Components
import workData from '../../data/workdata';
import icons from '../../assets/icons/icons';

//import Style classes
import "./TitleContent.css";

const titleContent = (props) => {

    const style = {
        width: props.data.size === 3 ? "33%" 
        : props.data.size === 2 ? "50%" 
        : props.data.size === 1 ? "100%" : "auto",
    }
    if(props.data.height) style.height = props.data.height;
    if(!props.data.text) style.backgroundColor = "black";
    console.log(props.data)
    return (
        <div className="WorkContent TitleContent" style={style}>
            <h1 className={workData[props.link].logo ? "Margin" : ""}>
                {workData[props.link].logo ? <img src={require("../../assets/works/"+props.link+"/"+workData[props.link].logo)} alt=""/> : null}
                {workData[props.link].title}
                <span>{workData[props.link].shortDescription}</span>
            </h1>
            {workData[props.link].status !== "experimental" ? 
                <div className="Platforms">
                    {Object.keys(workData[props.link][workData[props.link].status]).map((key, idx)=>{
                        return <a key={idx} href={workData[props.link][workData[props.link].status][key]}>{icons[key.toLowerCase()]}</a>;
                    })}
                </div> :
                null
            }

        </div>
    )
}

export default titleContent;