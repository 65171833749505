//import Libraries
import React from 'react';

//import Components
import Layout from '../../hoc/Layout/Layout';
import ContentBox from '../../components/ContentBox/ContentBox';
import Newscard from '../../components/Newscard/Newscard';
import WorkCard from '../../components/WorkCard/WorkCard';

import data from '../../data/data.json';
import worksData from '../../data/workdata';

//import Style classes

const home = (props) => {
    const news = [...data.news]
    const newsCards = news.reverse().slice(0,3).map((obj, idx)=>{
        return <Newscard data={obj} key={idx}/>
    })
    return (
        <Layout>
            <ContentBox title="News" more="news">
                {newsCards}
            </ContentBox>
            <ContentBox title="Our Games">
                {Object.keys(worksData).map((key, idx)=>{
                    let data = worksData[key]
                    return(
                        <WorkCard fullpage={false} 
                            title={data.title} 
                            link={key}
                            data={data} key={idx}></WorkCard>
                    )
                })}
            </ContentBox>
        </Layout>
    )
}

export default home;
