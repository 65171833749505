//import Libraries
import React from 'react';
import { Helmet } from 'react-helmet';

//import Components
import Layout from '../../hoc/Layout/Layout';
import ContentBox from '../../components/ContentBox/ContentBox';
import PaidType from './PaidType/PaidType';
import AdsType from './AdsType/AdsType';

import workData from '../../data/workdata';

//import Style classes
import './TermofUse.css';

const termofUse = (props) => {
    const textType = {
        rowrow: "paid",
        rgbit: "ads"
    }
    const gameName = props.match.params.work;
    const text = 
    textType[gameName] === "ads" ? <AdsType title={workData[gameName].title}/>
    : textType[gameName] === "paid" ? <PaidType title={workData[gameName].title}/>
    : null;

    return (
        <Layout>
            {workData[gameName] ? 
            <ContentBox title={"Term of Use - " + workData[gameName].title}>
                <Helmet>
                    <title>{workData[gameName].title + " Term of Use - Wayway Studio"}</title>
                </Helmet>
                {text}
            </ContentBox>
            : <ContentBox title="Sorry, can not find the page you are looking for"/>}
            
        </Layout>
    )
}

export default termofUse;