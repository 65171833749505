import React from 'react';

const icons = {
    facebook:
    <svg id="facebook-logo" data-name="facebook-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.39 486.39" width="100" height="100">
        <title>facebook</title>
        <path className="" d="M244.2,107.09A141,141,0,0,0,232,388.59V298.85H196.09V254H232V218.12a53.81,53.81,0,0,1,53.82-53.81h35.88v44.85H303.72c-9.9,0-17.94-.93-17.94,9V254h44.85l-17.94,44.85H285.79v84a141,141,0,0,0-41.59-275.8Z"/>
    </svg>,
    twitter:
    <svg id="twitter-logo" data-name="twitter-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.39 486.39" width="100" height="100">
        <title>twitter</title>
        <path className="" d="M364.19,188.6l.18,7.75c0,79.16-60.22,170.36-170.36,170.36a169.45,169.45,0,0,1-91.78-26.9,123.13,123.13,0,0,0,14.29.85A120.15,120.15,0,0,0,190.88,315a60,60,0,0,1-55.93-41.59,62.48,62.48,0,0,0,11.28,1A59.87,59.87,0,0,0,162,272.41a59.94,59.94,0,0,1-48-58.7v-.76a59.79,59.79,0,0,0,27.12,7.51,60,60,0,0,1-18.54-79.92A169.93,169.93,0,0,0,246,203.1,59.92,59.92,0,0,1,348,148.5,121.25,121.25,0,0,0,386,134a60,60,0,0,1-26.33,33.13,120,120,0,0,0,34.38-9.42A121,121,0,0,1,364.19,188.6Z"/>
    </svg>,
    youtube:
    <svg id="youtube-logo" data-name="youtube-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.39 486.39" width="100" height="100">
        <title>youtube</title>
        <path className="" d="M211.31,291.16l83.27-48-83.27-48Zm191.85-47.7s0,51.92-6.59,77a40.08,40.08,0,0,1-28.2,28.2c-25,6.59-125.18,6.59-125.18,6.59s-99.88,0-125.18-6.85a40.09,40.09,0,0,1-28.2-28.2c-6.59-24.77-6.59-76.95-6.59-76.95s0-51.91,6.59-76.95A40.9,40.9,0,0,1,118,137.79c25-6.59,125.18-6.59,125.18-6.59s100.14,0,125.18,6.85a40.09,40.09,0,0,1,28.2,28.2C403.42,191.28,403.16,243.46,403.16,243.46Z"/>
    </svg>,
    instagram:
    <svg id="instagram-logo" data-name="instagram-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.39 486.39" width="100" height="100">
        <title>instagram</title>
        <path className="" d="M326.75,112.94H159.64c-29.12,0-51.66,22.54-51.66,51.66V331.71c0,29.12,22.54,51.66,51.66,51.66H326.75c29.12,0,51.66-22.54,51.66-51.66V220.07h0V164.6C378.41,135.47,355.87,112.94,326.75,112.94Zm-25.31,30.51h40.22l5.55-.35v46.46H301.44Zm-97.08,76.62a47.55,47.55,0,0,1,77.66,0,49.26,49.26,0,0,1,9,28.08,47.84,47.84,0,0,1-95.69,0A49.26,49.26,0,0,1,204.37,220.07ZM354.14,331.71c0,14.56-12.83,27.39-27.39,27.39H159.64c-14.56,0-27.39-12.83-27.39-27.39V220.42h42c-3.47,6.93-5.55,17.68-5.55,27.74a74.54,74.54,0,1,0,149.08,0c0-9.71-2.08-20.8-5.55-27.74h42Z"/>
    </svg>,
    appstore: 
    <svg id="appstore" data-name="appstore" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.99 224.99" width="100" height="100">
        <title>appstore</title>
        <path d="M178.85,0H46.14A46.19,46.19,0,0,0,0,46.14V178.85A46.19,46.19,0,0,0,46.14,225H178.85A46.19,46.19,0,0,0,225,178.85V46.14A46.19,46.19,0,0,0,178.85,0ZM69.28,173.37a11,11,0,0,1-19-11l2.16-3.74H77.78ZM48.56,149.85a11,11,0,0,1,0-22H70.17L99.81,76.54l-8-13.91a11,11,0,0,1,4-15h0a11,11,0,0,1,15,4l1.69,2.93,1.69-2.93a11,11,0,1,1,19,11L95.54,127.88H119.3l12.69,22Zm127.88,0h-8.93l7.23,12.53a11,11,0,1,1-19,11L117.57,107.3l12.69-22,24.57,42.55h21.61a11,11,0,0,1,0,22Z"/>
    </svg>,
    googleplay:
    <svg id="googleplay" data-name="googleplay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.99 224.99" width="100" height="100">
        <title>googleplay</title>
        <polygon points="131.47 84.41 83.91 57.23 117.45 103.94 131.47 84.41"/>
        <polygon points="83.91 167.76 131.47 140.58 117.45 121.05 83.91 167.76"/>
        <path d="M64.79,47.7A6.44,6.44,0,0,0,59,54.13V170.87a6.49,6.49,0,0,0,3.22,5.6,6.42,6.42,0,0,0,2.61.82l46.51-64.79Z"/>
        <path d="M178.85,0H46.14A46.19,46.19,0,0,0,0,46.14V178.85A46.19,46.19,0,0,0,46.14,225H178.85A46.19,46.19,0,0,0,225,178.85V46.14A46.19,46.19,0,0,0,178.85,0Zm-3.14,126.78L73.6,185.15A16.45,16.45,0,0,1,49,170.87V54.13A16.45,16.45,0,0,1,73.6,39.85L175.72,98.22a16.45,16.45,0,0,1,0,28.56Z"/>
        <path d="M170.76,106.88,140.17,89.39,123.59,112.5l16.59,23.11,30.59-17.49a6.47,6.47,0,0,0,0-11.24Z"/>
    </svg>,
    steam:
    <svg id="steam" data-name="steam" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.99 224.99" width="100" height="100">
        <title>steam</title>
        <path d="M173.39,65.46a23.61,23.61,0,1,0,23.71,23.62A23.66,23.66,0,0,0,173.39,65.46Zm0,40.61a16.87,16.87,0,1,1,16.94-16.87A16.9,16.9,0,0,1,173.38,106.07Z"/>
        <path d="M178.85,0H46.14A46.19,46.19,0,0,0,0,46.14V178.85A46.19,46.19,0,0,0,46.14,225H178.85A46.19,46.19,0,0,0,225,178.85V46.14A46.19,46.19,0,0,0,178.85,0Zm4.64,117.7L163,135.18a27,27,0,0,1-53,10.22l-50.7-17.14A23.74,23.74,0,1,1,68.09,104l49,16.56a27,27,0,0,1,18.64-8L143,90.32c0-.44-.07-.88-.07-1.32a30.52,30.52,0,1,1,40.52,28.7Z"/>
        <path d="M132.11,123.78a18.22,18.22,0,0,0-4.05.47l10.08,3.41a13.1,13.1,0,1,1-12.41,23.07l-10.87-3.67a18,18,0,1,0,17.25-23.28Z"/>
        <path d="M45.24,92.63a16.64,16.64,0,1,0,4.9,32.56L42.2,122.5a13.1,13.1,0,1,1,12.4-23.07l5.24,1.77A16.71,16.71,0,0,0,45.24,92.63Z"/>
    </svg>,
    link: 
    <svg id="link" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000">
        <g>
            <path d="M499.6,9.9c-132,0-258.5,55-346.5,143c-86.9,88-143,214.5-143,346.5C10,681,107.9,836.1,252,923c71.5,42.9,159.5,67.1,247.5,67.1c137.5,0,257.4-55,346.5-144.1C964.9,727.2,1021,544.6,972.6,369.6C915.4,160.6,726.2,9.9,499.6,9.9L499.6,9.9z M435.8,743.7c-49.5,48.4-129.8,48.4-179.3,0c-49.5-49.5-49.5-129.8,0-179.3L383,437.8c49.5-49.5,129.8-49.5,179.3,0l3.3,3.3c1.1,1.1,2.2,1.1,2.2,2.2l5.5,6.6c0,0,0,0,0,1.1c7.7,13.2,5.5,25.3-5.5,36.3c-9.9,9.9-22,12.1-35.2,4.4c-1.1,0-5.5-5.5-13.2-11c-26.4-26.4-66-25.3-93.5-1.1L299.3,607.3c-26.4,25.3-26.4,67.1,0,93.5c26.4,25.3,67.1,25.3,93.5,0l75.9-77c28.6,14.3,57.2,14.3,84.7,1.1L435.8,743.7z M743.8,434.5L617.3,562.2c-49.5,48.4-129.8,48.4-179.3,0l-2.2-2.2l-2.2-2.2c-1.1-1.1-1.1-1.1-1.1-2.2L427,549c-7.7-13.2-5.5-25.3,4.4-35.2c11-11,23.1-13.2,36.3-6.6c1.1,0,2.2,1.1,4.4,3.3l8.8,8.8c26.4,25.3,67.1,25.3,93.5,0l126.5-127.6c26.4-25.3,26.4-67.1,0-92.4c-26.4-26.4-67.1-26.4-93.5,0l-75.9,75.9c-28.6-14.3-57.2-14.3-84.7-1.1l117.7-117.7c49.5-49.5,129.8-49.5,179.3,0c25.3,24.2,37.4,53.9,37.4,89.1C781.2,380.6,769.1,410.3,743.8,434.5z"/>
        </g>
    </svg>
}


export default icons;