//import Libraries
import React, {Component} from 'react';

//import Components


//import Styles
import './Namecard.css';
let addClass = "";

class Namecard extends Component{
    
    render(){
        return(
            <div className={"Namecard"+addClass} style={{borderColor: this.props.data.color ? this.props.data.color : "rgba(255,255,255,0.7)"}}>
                {/* <div className="LineColor" style={{backgroundColor: this.props.data.color ? this.props.data.color : "rgba(255,255,255,0.7)"}}></div> */}
                <h3>{this.props.data.name}</h3>
                <p>{this.props.data.company}</p>
                <h6>{this.props.data.occupation.join("\n")}</h6>
            </div>
        )
    }
}


export default Namecard;