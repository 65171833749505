//import Libraries
import React from 'react';

//import Components


//import Style classes

const steam = (props) => {

    return (
        <div class="PromotionInstructions">
          <p>프로모션 키를 등록하여 게임을 설치할 경우 다음 안내사항을 따라 주세요:</p>
          <ol>
            <li><a href="https://store.steampowered.com/about/">스팀 클라이언트 소프트웨어</a>를 실행하고 로그인 해주세요</li>
            <li>좌측 하단의<strong>게임 추가</strong> 버튼을 클립해주세요</li>
            <li><strong>스팀에 제품등록</strong>을 선택합니다</li>
            <li>화면의 안내사항에 따라 진행해주세요</li>
          </ol>
          <hr/>
          <p>Please follow these instructions to activate a new retail purchase on Steam:</p>
          <ol>
            <li>Launch the <a href="https://store.steampowered.com/about/">Steam client software</a> and log into your Steam account.</li>
            <li><strong>ADD A GAME Menu</strong> on the lower left-hand side</li>
            <li>Choose <strong>Activate a&nbsp;Product on Steam...</strong></li>
            <li>Follow the onscreen instructions to complete the process.</li>
          </ol>
          <img src={require('../../../assets/special/promotion-instruction/add-a-game.png')} alt="App Store Promotion Code Usage"/>
        </div>
    )
}

export default steam;
