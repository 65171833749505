//import Libraries
import React, { Component } from 'react';
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './App.css';

//import Components
import Home from './pages/Home/Home';
import About from './pages/About/About';
import News from './pages/News/News';
import Works from './pages/Works/Works';
import Privacy from './pages/Privacy/Privacy';
import GeneralTermOfUse from './pages/Privacy/GeneralTermofUse';
import TermofUse from './pages/TermofUse/TermofUse';
import PromotionInstructions from './pages/PromotionInstructions/PromotionInstructions';
import RgbitColorGen from './pages/Special/RgbitColorGen';

//import Actions

//import Style classes

//import Google Analytics

const reload = () => window.location.reload();

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/works/dredscrossing" exact>
              <Redirect to="/works/aksun"/>
            </Route>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/news" exact component={News} />
            <Route path="/works" exact component={Works} />
            <Route path="/works/:work" exact component={Works} />
            <Route path="/privacy/:work" exact component={Privacy} />
            <Route path="/termofuse" exact component={GeneralTermOfUse} />
            <Route path="/termofuse/:work" exact component={TermofUse} />
            <Route path="/promotion-instruction" exact component={PromotionInstructions} />
            <Route path="/promotion-instruction/:platform" exact component={PromotionInstructions} />
            <Route path="/works/rgbit/color-experiment" exact component={RgbitColorGen} />
            <Route path="/app-ads.txt" onEnter={reload} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
