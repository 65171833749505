//import Libraries
import React, {Component} from 'react';

//import Components

//import Styles
import './ImageCard.css';
let addClass = "";

class ImageCard extends Component{

    render(){
        let imageStyle = null;
        let textBoxStyle = null;
        if(this.props.widthRatio){
            const imageRatio = this.props.widthRatio[0];
            const textBoxRatio = this.props.widthRatio[1];
            const allRatio = imageRatio + textBoxRatio;
            imageStyle = {
                width: imageRatio/allRatio*100+"%"
            }
            textBoxStyle = {
                width: textBoxRatio/allRatio*100+"%"
            }
        }
        return(
            <div className={"ImageCard"+addClass}>
                <div className="Image" style={imageStyle ? imageStyle : null}>
                    <img src={require('../../assets/'+this.props.path)} alt=""/>
                </div>
                <div className="TextBox" style={textBoxStyle ? textBoxStyle : null}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


export default ImageCard;