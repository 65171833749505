//import Libraries
import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

//import Components
import Navigation from '../../components/Navigation/Navigation';
import Transition from 'react-transition-group/Transition';

import logo from '../../assets/logo/main-logo';

//import Styles
import './Layout.css';

let addClass = "";

ReactGA.initialize('UA-130788114-1');


class Layout extends Component{
    state = {
        loading: true
    }
    componentDidMount() {
        if(!this.props.keepScroll)window.scrollTo(0, 0);

        window.location.hash = window.decodeURIComponent(window.location.hash);
        const scrollToAnchor = () => {
            const hashParts = window.location.hash.split('#');
            if (hashParts.length > 2) {
            const hash = hashParts.slice(-1)[0];
            document.querySelector(`#${hash}`).scrollIntoView();
            }
        };
        scrollToAnchor();
        window.onhashchange = scrollToAnchor;

        setTimeout(()=>{
            this.setState((prevState)=>{
                if(prevState.loading !== false){
                    return {loading: false};
                }
            });
            console.log("[GA]"+window.location.pathname + window.location.search)
            ReactGA.pageview(window.location.pathname + window.location.search);
        }, 600)
    }

    render(){
        if(this.props.fullpage === true){
            addClass = " Fullpage"
        }else{ addClass ="" }
        return(
            <div className={"Layout"}>
                <Helmet>
                    <title>{"Wayway Studio - Right Way is the Fastest Way"}</title>
                    <meta name="description" content="Official Website of Wayway Studio. An independent game development team based in South Korea who released Rowrow, RGBit and more well designed indie games" />
                </Helmet>
                <Transition in={this.state.loading} timeout={600}>
                    {(state) => {
                        return(
                            <div className={"TransitionPage "+state}>
                                <div className="InsideBox">
                                    {logo}
                                    <p>Wayway Studio.</p>
                                </div>
                                
                            </div>
                        )
                    }}
                </Transition>
                <Navigation/>
                <div className={'ContentWrapper'+addClass}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


export default Layout;