//import Libraries
import React from 'react';
import { Link } from 'react-router-dom';

//import Components

//import Style classes
import "./LinkContent.css";
import icons from '../../assets/icons/icons';

const linkContent = (props) => {

    const style = { };
    if(props.data.height) style.height = props.data.height;
    if(!props.data.background) style.backgroundColor = "black";

    let classes = [
        "WorkContent",
        "LinkContent",
        props.data.size === 3 ? "third" 
        : props.data.size === 2 ? "half" 
        : props.data.size === 1 ? "full" : "",
    ]
    // console.log
    const finalComponent = (
        <React.Fragment>
            <img className="BackgroundImage" src={require("../../assets"+props.data.background)} alt=""/>
            {props.data.text ? <h3>{props.data.title}</h3>
            : <h1>{props.data.title}<hr/></h1>}
            {props.data.text && typeof props.data.text === "string" 
            ? <p>{props.data.text}</p> 
            : props.data.text && Array.isArray(props.data.text) ? <ul> {props.data.text.map((obj, idx)=>{return <li key={idx}>{obj}</li>})}</ul> : null}
            {icons.link}
        </React.Fragment>
    )
    if(props.data.link.type === "internal"){
        return (
            <Link to={""+props.data.link.address} className={classes.join(" ")} style={style}>
                {finalComponent}
            </Link>
        )
    }else if(props.data.link.type === "external"){
        return(
            <a href={props.data.link.address} className={classes.join(" ")} style={style}>
                {finalComponent}
            </a>
        )
    }
}

export default linkContent;